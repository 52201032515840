<template>
  <PanelSection title="Mi usuario" :hide-back-button="false">
    <div class="authentication-form">
      <v-container>
        <CustomCard>
          <v-form novalidate @submit.prevent="onSubmit">
            <!-- Name -->
            <v-text-field
              v-model="$v.formFields.name.$model"
              label="Name"
              :error-messages="checkFieldErrors('name')"
              @input="touchField('name')"
              @blur="touchField('name')"
            />
            <!-- Email (disabled) -->
            <v-text-field
              :value="userData.email"
              disabled
              label="Email"
              type="email"
            />
            <small class="text--disabled"
              >El email no puede ser modificado</small
            >
            <!-- Change password (checkbox) -->
            <v-checkbox v-model="changePassword">
              <template v-slot:label>Cambiar contraseña del usuario</template>
            </v-checkbox>
            <!-- Password -->
            <template v-if="changePassword">
              <v-text-field
                v-model="$v.formFields.password.$model"
                label="Contraseña"
                prepend-icon="mdi-lock-outline"
                :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="togglePassword ? 'text' : 'password'"
                :error-messages="checkFieldErrors('password')"
                @input="touchField('password')"
                @blur="touchField('password')"
                @click:append="togglePassword = !togglePassword"
              />
            </template>
            <v-card-actions>
              <!-- Form action buttons -->
              <FormButtons
                accept-button-text="Guardar"
                :accept-button-loading="formProcessing"
                cancel-button-hide
              />
            </v-card-actions>
          </v-form>
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Components
import PanelSection from '@/components/ui/PanelSection'
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuthenticationForm',
  components: { PanelSection, CustomCard, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],
  data() {
    return {
      // Form
      formFields: {
        name: null,
        password: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        }
      },
      // Others
      changePassword: false, // to change password field
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  mounted() {
    this.setInitalValues()
  },
  methods: {
    ...mapActions('authentication', ['updateUserAuth']),
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setInitalValues() {
      this.formFields = { ...this.formFields, name: this.userData.name }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        await this.updateUserAuth({
          ...this.formFields,
          changePassword: this.changePassword
        })
        // show info success
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })
      } catch (error) {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations() {
    const rules = {
      formFields: {
        name: {
          required
        }
      }
    }

    if (this.changePassword) {
      rules.formFields.password = {
        required,
        minLength: minLength(6)
      }
    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.authentication-form {
  height: 100%;
}
</style>
